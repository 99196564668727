import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Constants } from 'app/core/data/constants';

@Injectable({
  providedIn: 'root'
})
export class CookiesUtilsService {

  constructor(
    private _cookieService: SsrCookieService,
  ) { }

  setAccessToken({ token }: { token: string }): void {
    this._cookieService.set(Constants.ACCESS_TOKEN, token);
  }
  getAccessToken(): string {
    return (this._cookieService.get(Constants.ACCESS_TOKEN) ?? null);
  }
  getDecodedAccessToken(): any {
    return jwtDecode(this._cookieService.get(Constants.ACCESS_TOKEN) ?? '');
  }
  getUser(): any {

  }
  removeAccessToken(): void {
    this._cookieService.delete(Constants.ACCESS_TOKEN);
  }
}

